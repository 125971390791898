import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Fade, Hidden, IconButton, Menu, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavBarItem from "./NavBarItem";
import clsx from "clsx";
import { useNavigate } from "react-router";
import logo from "../../assets/logo.png";
import theme from "../../theme";
import Cart from "./Cart";
import bg from "../../assets/img/fons-2.jpg";
import { ReactComponent as MenuClose } from "../../assets/icons/menu-creu.svg";
import { ReactComponent as MenuOpen } from "../../assets/icons/menu.svg";

const useStyles = makeStyles((theme) => ({
	main: {
		width: "100%",
		zIndex: 100,
		paddingBottom: 20,
		position: "fixed",
	},
	nav: {
		transition: "0.2s",
	},
	navBar: {
		backgroundColor: "transparent !important",
		boxShadow: "none",
	},
	navB: {
		paddingTop: 20,
	},
	navBarDown: {
		background: `url(${bg})`,
		backgroundSize: "cover",
		backgroundAttachment: "fixed",
		paddingTop: 5,
		paddingBottom: 5,
		boxShadow: "none",
		maskSize: "cover",
		maskRepeat: "no-repeat",
		maskPosition: "center",
	},
	logo: {
		fill: theme.color.primary,
	},
	maskTop: {
		width: "100%",
	},
	menu: {
		"& .MuiPaper-root": {
			marginTop: 10,
			// background: theme.color.background,
			background: "transparent",
			[theme.breakpoints.down("1660")]: {
				background: theme.color.background,
			},
			padding: 0,
			boxShadow: "none",
		},
	},
	reservar: {
		cursor: "pointer",
		backgroundColor: theme.color.primary,
		padding: "5px 15px 5px 15px",
		color: "white",
	},
}));

const TopBarPublic = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const [scrollY, setScrollY] = useState(window.scrollY);
	const navigate = useNavigate();
	const items = [
		{
			title: "Inicio",
			to: "/",
		},
		{
			title: "Tienda",
			to: "/tienda",
		},

		{
			title: "Cupón regalo",
			to: "/tienda",
			hash: "#cupon",
		},
		{
			title: "Escape Rooms",
			children: [
				{
					title: "El Cóctel del Doctor",
					to: "/escapes/8",
					state: 8,
				},
				{ title: "La Casa", to: "/escapes/6", state: 6 },
				{ title: "El Secreto de los Krugger", to: "/escapes/secreto-krugger" },
			],
		},
		{
			title: "Insomnia Hotel",
			to: "/hotel",
		},
		// { title: "Habitaciones", to: "/habitacions" },
		// { title: "Restaurante “K”", to: "/restaurant" },

		{
			title: "Sobre la corporación",
			to: "/sobre-la-corporacion",
		},
		{
			title: "Grupos grandes",
			to: "/grupos-grandes",
		},
		{
			title: "Franquicia",
			to: "/franquicia",
		},
		{
			title: "Contacto",
			to: "/contacte",
		},
		// {
		// 	title: "Tickets Sorteo",
		// 	to: "/tickets",
		// },
	];

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	return (
		<Box className={classes.main}>
			<AppBar elevation={0} className={classes.navBar}>
				<Toolbar className={clsx(classes.nav, scrollY > 150 ? classes.navBarDown : classes.navB)}>
					<Box mr={12}>
						<IconButton onClick={handleClick}>
							<Fade in={open}>
								<Box position={"absolute"} left={15}>
									<MenuClose fill={theme.color.primary} width={25} />
								</Box>
							</Fade>
							<Fade in={!open}>
								<Box position={"absolute"} left={15}>
									<MenuOpen fill={theme.color.primary} width={25} />
								</Box>
							</Fade>
						</IconButton>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
							className={classes.menu}
						>
							{items?.map((item) => (
								<NavBarItem
									to={item.to}
									key={item.title}
									title={item.title}
									hash={item.hash}
									child={item.children}
									handleClose={handleClose}
									state={item.state}
								/>
							))}
						</Menu>
					</Box>
					<Box flexGrow={1.1} />

					<Hidden mdDown>
						<Box
							style={{
								display: "flex",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={() => navigate("/")}
						>
							<img src={logo} width={scrollY > 150 ? 220 : 290} alt="Logo Insomnia Corp" style={{ transition: "0.2s" }} />
						</Box>
					</Hidden>
					<Box flexGrow={1} />
					<Box display={"flex"}>
						<Typography color={theme.color.secondary} onClick={() => navigate("/reserves")} mr={3} className={classes.reservar}>
							Reservas
						</Typography>

						{/* <Hidden mdDown>
							<Typography color={theme.color.secondary} onClick={() => navigate("/tickets")} mr={3} className={classes.reservar}>
								Tickets sorteo
							</Typography>
						</Hidden> */}
					</Box>
					<Cart />
				</Toolbar>
			</AppBar>
		</Box>
	);
};

TopBarPublic.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

export default TopBarPublic;
