import { Close } from "@mui/icons-material";
import {
	Avatar,
	Dialog,
	DialogContent,
	Divider,
	Fab,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import paper from "../../../assets/img/paper-popup.png";
import { makeStyles } from "@mui/styles";
import "../../../theme/calStyle.css";
import moment from "moment";
import "moment/locale/es";
import "moment/min/moment-with-locales";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router";
import CustomButton from "../../../components/elements/CustomButton";
import clsx from "clsx";

const talles = [
	{ id: "S", name: "S" },
	{ id: "M", name: "M" },
	{ id: "L", name: "L" },
	{ id: "XL", name: "XL" },
];

export default function DialogMaterial({ select, open, setOpen, scapes = [], setSelect = false }) {
	const [quantitat, setQuantitat] = useState(Number(select?.material?.cupo) === 1 ? 100 : 1);
	const [cart, setCart] = useLocalStorage("cart", []);
	const [variant, setVariant] = useState("");
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const matches = useMediaQuery("(min-width:960px)");
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		open && setQuantitat(Number(select?.material?.cupo) === 1 ? 100 : 1);
	}, [open]);

	const handleClose = () => {
		setQuantitat(1);
		setVariant("");
		setOpen(false);
	};

	const addCard = async (nav) => {
		setLoading(true);

		let result = [...cart];
		result.push({
			index: result.length + 1,
			quantitat: quantitat,
			producte_id: select.id,
			preu: select?.material.preu * quantitat,
			producte: select,
			pagat: select?.material.preu * quantitat,
			variant: variant ?? "",
			created_at: moment(),
		});
		setCart(result);
		enqueueSnackbar("Producto añadido", {
			variant: "success",
		});
		setError(false);
		if (nav) {
			navigate("/reserves/checkout");
		}
		handleClose();

		setLoading(false);
	};

	return (
		<Dialog
			onClose={handleClose}
			open={open}
			PaperProps={{
				style: {
					borderRadius: 0,
					background: `url(${paper})`,
					backgroundRepeat: "round",
					backgroundSize: "cover",
					padding: matches ? 30 : 0,
				},
			}}
			maxWidth="xl"
			fullScreen={!matches}
		>
			<DialogContent>
				<Box className={classes.close}>
					<Fab classesName={classes.closeButton} size="small" color="secondary" onClick={handleClose}>
						<Close sx={{ fontSize: 30 }} />
					</Fab>
				</Box>
				<Grid container spacing={3} display="flex">
					<Grid item md={6} xs={12} p={3}>
						<Box
							className={"inbox"}
							my={5}
							sx={{
								transition: "0.2s",
								alignItems: "center",
								display: "flex",
								justifyContent: "center",
								textAlign: "center",
							}}
						>
							<Box
								sx={{
									width: "100%",
									height: "100%",
								}}
							>
								<Avatar src={"https://api.insomniacorp.com/storage/" + select?.foto} variant="square" sx={{ width: "100%", height: "100%" }} />
							</Box>
						</Box>
					</Grid>
					<Grid item md={6} xs={12} my={3}>
						<Typography variant="h6" mt={2} letterSpacing={0} fontSize={45}>
							{select?.nom}
						</Typography>
						<Box my={3}>
							<Divider sx={{ border: 1 }} />
						</Box>
						<Typography>{select?.desc}</Typography>
						{Number(select?.material?.cupo) === 1 && (
							<Box my={3}>
								<Typography variant="h3">¿Que deseas regalar?</Typography>
								<Typography variant="caption" color="black">
									Recomendaciones de precio del cupón
								</Typography>
								<Typography fontWeight={700}>Escape room El coctel del Doctor o la Casa:</Typography>
								<Typography>
									- 2 personas 80€ / 90€
									<br />
									- 3 personas 90€ / 105€
									<br />
									- 4 personas 100€ / 120€
									<br />
									- 5 personas 125€ / 140€
									<br />
									- 6 personas 150€ / 162€
									<br />
								</Typography>
								<Typography fontWeight={700}>Noche en Insomnia hotel:</Typography>
								<Typography variant="caption" color="black">
									(Alojamiento + escape room de la habitación)
								</Typography>
								<Typography>
									- Habitación doble 150€
									<br />
									- Habitación Triple 225€
									<br />
								</Typography>
								<Typography fontWeight={700}>⁠Cena en el Restaurante “K”:</Typography>
								<Typography variant="caption" color="black">
									(Alojamiento + escape room de la habitación)
								</Typography>
								<Typography>- Aconsejamos hacer un bono regalo de 25€ por persona</Typography>
								<Typography variant="caption" color="black">
									Nuestro restaurante es a la carta, en caso de sobrepasar los 25€ por persona, se pagará al finalizar la cena. Si no se llega
									al precio pagado, les ofreceremos un bono para futuras reservas o merchandising.
								</Typography>
							</Box>
						)}
						<Typography variant="h2" fontWeight={500} my={2}>
							{select?.material?.preu * quantitat} €
						</Typography>
						<Box my={5}>
							<Divider sx={{ border: 1 }} />
						</Box>

						{Number(select?.material?.talles) === 1 && (
							<Box my={5} pb={0.5}>
								<Typography variant="h6" fontWeight={500} my={2} fontSize={20}>
									Talla
								</Typography>
								<Stack direction={"row"} spacing={2} mb={3}>
									{talles.map((item, index) => (
										<Box className={clsx(item.id === variant && classes.selected, classes.pill)} onClick={() => setVariant(item.id)}>
											<Typography style={{ transition: "0.2s" }} pr={1} pl={1}>
												{item.name}
											</Typography>
										</Box>
									))}
								</Stack>
							</Box>
						)}
						{Number(select?.material?.cupo) === 1 && (
							<Box my={2}>
								<TextField
									value={variant}
									onChange={(val) => setVariant(val.target.value)}
									name="variant"
									label={"Nombre del cupón"}
									fullWidth
									required
								/>
							</Box>
						)}

						<Box mb={4}>
							<TextField
								value={quantitat}
								onChange={(val) => setQuantitat(val.target.value)}
								name="quantitat"
								label={Number(select?.material?.cupo) === 1 ? "Precio del cupón" : "Cantidad"}
								type={"number"}
								defaultValue={Number(select?.material?.cupo) === 1 ? 100 : 1}
								fullWidth
							/>
						</Box>
						{Number(select?.material?.cupo) !== 1 && (
							<Box mb={3}>
								<Typography variant="caption" color="black">
									Gastos de envio: 6€
								</Typography>
								<br />
								<Typography variant="caption" color="black">
									Por la compra de productos merchandaising por valor de más de 50€, gastos de envío gratis.{" "}
								</Typography>
							</Box>
						)}
						<Stack direction={"row"} spacing={2}>
							<CustomButton title="Añadir al carro y comprar más" fullWidth onClick={() => addCard(false)} />
							<CustomButton
								type="submit"
								title="Comprar ahora"
								fullWidth
								onClick={() => {
									addCard(true);
								}}
							/>
						</Stack>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

const useStyles = makeStyles((theme) => ({
	img: {
		width: 50,
		height: 50,
	},
	close: {
		position: "absolute",
		top: 20,
		right: 20,
		zIndex: 100,
	},
	closeButton: {
		backgroundColor: theme.color.secondary,
	},
	pill: {
		transition: "0.2s",
		border: "2px solid black",
		display: "table",
		cursor: "pointer",
		textAlign: "center",
		width: 60,
		"& img": {
			transition: "0.2s",
		},
		"&:hover": {
			backgroundColor: theme.color.third + "90",
			"& img": {
				scale: 1.1,
			},
		},
	},
	selected: {
		backgroundColor: theme.color.third + "90",
		"& .MuiTypography-root": {
			color: "white",
		},
	},
	disabled: {
		border: "2px solid black",
		display: "table",
		width: "100%",
		backgroundColor: "#1a191820",
		cursor: "not-allowed",
		"& .MuiTypography-root": {
			color: "#1a191840",
		},
	},
}));
