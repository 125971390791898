import { Add } from "@mui/icons-material";
import { Grid, IconButton, Input, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { addElement, getElements, updateElement } from "../../../API/API";
import Loading from "../../../components/layouts/Loading";
import PageAdmin from "../../../components/layouts/PageAdmin";
import CartaCategoriaDialogAdd from "./CartaCategoriaDialogAdd";
import CartaCategoriaItem from "./CartaCategoriaItem";
import CartaDialogAdd from "./CartaDialogAdd";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../components/elements/CustomButton";
import { useSnackbar } from "notistack";

function DegustacioAdmin() {
	const classes = useStyles();
	const [cartes, setCartes] = useState([]);
	const [open, setOpen] = useState(false);
	const [openCarta, setOpenCarta] = useState(false);
	const [loading, setLoading] = useState(true);
	const [update, setUpdate] = useState(0);
	const [select, setSelect] = useState();
	const [selectCarta, setSelectCarta] = useState();
	const [preu, setPreu] = useState();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		const obtenir = async () => {
			const { result } = await getElements("carta_categories_degustacio");
			const resultPreu = await getElements("getPreu");
			console.log(resultPreu);
			setCartes(result);
			setPreu(resultPreu.result.preu);
			setLoading(false);
		};
		obtenir();
	}, [update]);

	const obrir = () => {
		setSelect("");
		setOpen(true);
	};

	const updatePreu = async () => {
		setLoading(true);
		try {
			await addElement("updatePreu", { preu: preu });
			enqueueSnackbar("Preu guardat", {
				variant: "success",
			});
		} catch (error) {
			enqueueSnackbar("Alguna cosa no ha anat bé...", {
				variant: "error",
			});
		}
		setLoading(false);
	};

	return (
		<PageAdmin
			title="Carta Menú Degustació"
			button={
				<Tooltip title="Afegir categoria carta">
					<IconButton onClick={obrir}>
						<Add />
					</IconButton>
				</Tooltip>
			}
		>
			<Loading loading={loading}>
				<Grid spacing={3} container>
					{cartes?.map((item) => (
						<>
							<CartaCategoriaItem
								item={item}
								setSelect={setSelect}
								setOpen={setOpen}
								setUpdate={setUpdate}
								openCarta={openCarta}
								setOpenCarta={setOpenCarta}
								setSelectCarta={setSelectCarta}
							/>
						</>
					))}
				</Grid>
				<Box className={classes.item} textAlign="center">
					<Box mb={3}>
						<Typography variant="h3">Preu menú degustació</Typography>
					</Box>
					<Input value={preu} onChange={(val) => setPreu(val.target.value)} placeholder="Preu" />
					<CustomButton onClick={updatePreu} title={"Actualitzar preu"} loading={loading} />
				</Box>
			</Loading>
			<CartaCategoriaDialogAdd setUpdate={setUpdate} open={open} setOpen={setOpen} cat={select} ubicacio={3} />

			<CartaDialogAdd setUpdate={setUpdate} open={openCarta} setOpen={setOpenCarta} carta={selectCarta} catId={select?.id} setCarta={setSelectCarta} />
		</PageAdmin>
	);
}

export default DegustacioAdmin;

const useStyles = makeStyles((theme) => ({
	item: {
		backgroundColor: "white",
		padding: 20,
		position: "relative",
		marginTop: 30,
	},
	edit: {
		position: "absolute",
		top: 10,
		right: 10,
	},
}));
